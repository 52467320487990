body {
  font-family: 'Gotham-Ultra', 'Gotham-Light', Arial, sans-serif;
}

@keyframes border-glow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wood-grain {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/*HEADER*/
.header-contain {
  background-image: url('./assets/main/texture-ardoise-brosse.webp');
  background-size: 100% calc(100% - 12vw);
  background-repeat: no-repeat;
}

.top-header-images {
  display: flex;
  justify-content: space-evenly;
}

.header-navigation {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.contact-follow {
  margin: 0 0 25px 0;
}

.img-real-france {
  width: 100%;
  max-width: 225px;
  aspect-ratio: 225 / 139;
  height: auto;
  object-fit: contain;
}

.logo-title {
  position: relative;
  left: 30px;
  min-width: 150pxS;
}

.logo-francemug {
  width: 100%;
  max-width: 390px;
  aspect-ratio: 390 / 200;
  height: auto;
  object-fit: contain;
  min-width: 150px;
}

.img-contact {
  width: 100%;
  max-width: 130px;
  aspect-ratio: 130 / 33;
  height: auto;
  object-fit: contain;
}

.img-facebook {
  width: 100%;
  max-width: 154px;
  aspect-ratio: 154 / 33;
  height: auto;
  object-fit: contain;
}

.img-boutique {
  width: 100%;
  max-width: 251px;
  aspect-ratio: 251 / 119;
  height: auto;
  object-fit: contain;
}

.contact-link {
  display: inline-block;
}

.facebook-link {
  padding-left: 23px;
  display: inline-block;
}

.boutique-link {
  display: block;
  position: relative;
  left: 10px;
}

.header-title {
  text-indent: -9999px;
  overflow: hidden;
  margin: 0;
}

.header-slogan-container {
  font-family: 'Gotham-Ultra';
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  min-height: 150px;
}

.header-slogan {
  margin: 0;
  font-weight: bold;
  width: 40%;
  text-align: center;
  color: #fffeff;
  line-height: 0px;
  position: relative;
  bottom: 15px;
  font-size: 2em;
}
.title2-text {
  font-size: 35px;
}

.title2-minus {
  font-size: 25px;
}

.img-etagere {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.invisible {
  opacity: 0;
  transition: opacity 0.2s; /* Transition pour chevaucher avec fade-in */
}

.mug-common {
  object-fit: contain;
  height: 45.5%;
  position: absolute;
}

.mug1-design {
  top: 34.5%;
  left: 28.89vw;
}

.mug2-design {
  top: 34.5%;
  left: 45.7%;
}

.mug3-design {
  top: 34.5%;
  left: 62%;
}

.header-comm {
  background-image: url('./assets/main/texture-ardoise-brosse.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.title-comm {
  margin: 0;
  padding: 75px 0 20px 0;
  text-align: center;
  font-family: 'Gotham-Ultra';
  font-size: 2em;
  color: #fde581;
}

.slogan-comm {
  color: #fffeff;
  text-align: center;
  font-size: 1.7em;
  margin: 0;
  padding-bottom: 35px;
  font-family: 'Gotham-Light';
  font-weight: bold;
  line-height: 1;
}

.slogan-text-comm {
  margin: 0;
}

.text-comm-container {
  display: flex;
  justify-content: center;
  font-family: 'Gotham-Light';
  color: #fffeff;
  padding-bottom: 70px;
  min-height: 150px;
}

.under-comm-container {
  box-sizing: border-box;
  margin: 0 30px;
  width: 30%;
  min-height: 150px;
}

.text-comm-top {
  padding-top: 21px;
}

/* NAV BAR */

.nav-bar {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  min-height: 50px;
  margin: 2% 3%;
}

.nav-bar li {
  min-width: 120px;
  min-height: 50px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-bar li:hover {
  background-color: #d3d3d3;
}

.nav-bar li.active {
  background-color: #2b2d74;
  color: white;
}


/* MAIN */

main {
  font-family: 'Gotham-Ultra', sans-serif;
  background-image: url('./assets/main/texture_icones.webp');
  background-repeat: repeat;
  min-height: 800px;
  min-width: 800px;
}

.mug-list {
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 70px 5% 0 5%;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Ajuste la hauteur du spinner */
  min-height: 100px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animated-slide {
  animation: slide-in 0.5s cubic-bezier(0.175, 0.385, 0.12, 1.275);
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/*
main {
  position: relative;
  width: 100%;
  min-height: 100vh; 
  background: linear-gradient(120deg, #D3D3D3 25%, #A7A7A7 75%);
  background-size: 200% 200%;
  overflow: hidden;  
}

.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  animation: bubble-rise 20s infinite ease-in-out;
  opacity: 0.6;
}

.bubble:nth-child(1) {
  left: 10%;
  width: 60px;
  height: 60px;
  animation-duration: 25s;
  animation-delay: 1s;
}

.bubble:nth-child(2) {
  left: 25%;
  width: 30px;
  height: 30px;
  animation-duration: 20s;
  animation-delay: 3s;
}

.bubble:nth-child(3) {
  left: 40%;
  width: 50px;
  height: 50px;
  animation-duration: 30s;
  animation-delay: 5s;
}

.bubble:nth-child(4) {
  left: 55%;
  width: 80px;
  height: 80px;
  animation-duration: 20s;
  animation-delay: 2s;
}

.bubble:nth-child(5) {
  left: 70%;
  width: 40px;
  height: 40px;
  animation-duration: 15s;
  animation-delay: 4s;
}

.bubble:nth-child(6) {
  left: 85%;
  width: 70px;
  height: 70px;
  animation-duration: 18s;
  animation-delay: 6s;
}

@keyframes bubble-rise {
  0% {
      transform: translateY(0) scale(1);
      opacity: 0.6;
  }
  50% {
      opacity: 1;
  }
  100% {
      transform: translateY(-900vh) scale(1.2); 
      opacity: 0;
  }
}*/

.mug-display {
  width: 18%;
  margin: 0 25px 75px 25px;
  font-family: 'Gotham-Light';
}

.mug-card {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.mug-card:hover {
  transform: scale(1.05);
}

.mug-image {
  width: 100%;
  height: auto;
}

.mug-info {
  text-align: center;
}

.mug-title {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.mug-price {
  color: #2c3e50;
  font-size: 1em;
  font-weight: bold;
}

.filter-bar {
  margin-bottom: 20px;
}

.filter-bar button {
  margin-right: 10px;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
}

.filter-bar button:hover {
  background-color: #2980b9;
}

.mug-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.principal-mug-img {
  object-fit: contain;
  width: 10%;
}

.main-media {
  cursor: pointer;
}

.main-media img,
.main-media video {
  width: 100%;
  max-width: 400px;
  margin-bottom: 5px;
  border-radius: 8px;
}

.price-link-contain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-amazon {
  /*
  font-weight: bold;
  color: #FF0000;
  margin: 0;
  border: 1px solid;
  padding: 7px;
  border-radius: 50%;
  background-color: #fffeff;
  */
  text-indent: -9999px;
  overflow: hidden;
}

.link-amazon {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.button-amazon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50px;
  border-radius: 35px;
  background: #231F20;
  color: #fffeff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 0;
  font-weight: bold;
  border: 2px outset #fffeff;
  padding: 5px 0;
}

.button-amazon::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%; /* Commence hors du bouton */
  width: 300%; /* Largeur large pour couvrir tout le bouton */
  height: 100%;
  border-radius: 15px;
  border: 3px solid transparent;
  background-image: linear-gradient(120deg, transparent, #fff, transparent);
  z-index: 1;
  transition: none; /* Pas de transition sur l'état initial */
  opacity: 0;
}

.button-amazon:hover::before {
  opacity: 1;
  animation: border-glow 1s linear forwards; /* Animation se produit une seule fois */
}

.text-button-amazon {
  font-family: 'Gotham-Light';
  font-size: 1em;
}

.img-logo-amazon {
  object-fit: contain;
  width: 40%;
}

.thumbnails {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.video-thumbnail-container {
  position: relative;
  display: inline-block;
}

.video-thumbnail {
  display: block;
  height: auto;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 20px solid grey; /* Triangle */
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  opacity: 0.8;
}

/* LIGHTBOX */

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.lightbox-media {
  max-width: 500px;
  max-height: 500px;
}

.lightbox-image {
  max-width: 100%;
  max-height: 100%;
}

.lightbox-video {
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto; /* Permet de capturer les événements, mais uniquement pour la vidéo */
}

.lightbox-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 36px;
  color: #fffeff;
  cursor: pointer;
  z-index: 1001;
}

.lightbox-close:hover {
  color: #FF0000;
}

.close-btn {
  pointer-events: all; /* Assure que le clic est capturé par la croix même pour la vidéo */
}

.lightbox-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 1001;
}

.lightbox-prev,
.lightbox-next {
  font-size: 55px;
  color: #fffeff;
  cursor: pointer;
  user-select: none;
  padding: 5px;
}

.lightbox-prev:hover,
.lightbox-next:hover {
  color: #fde581;
}

.lightbox-video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.lightbox-video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

.lightbox-video::-webkit-media-controls-panel {
  width: calc(100% - 25px); /* Pour compenser la suppression du bouton plein écran */
}

.lightbox-video::-moz-media-controls-fullscreen-button {
  display: none;
}

.lightbox-video::backdrop {
  display: none; /* Empêche également le plein écran en mode non-Chrome */
}



/*FOOTER*/
.caract-mug-container {
  background-color: #f0f0f0;
  display: flex;
  height: 400px;
  text-align: center;
  padding: 0 15%;
}

.caract-icones-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.8em;
}

.icones-container {
  width: 30%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.icone-caract {
  width: 55px;
}

.text-caract {
  padding: 0 15px;
}

.title-caract {
  padding: 0 75px;
  margin: 0;
}

.under-caract-container {
  margin-top: 5px;
}

.under-text-caract {
  font-size: 0.9em;
  margin: 0;
}

.footer-link {
  background-image: url('./assets/main/texture-ardoise-brosse.webp');
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0.7em;
  color: #fffeff;
  height: 200px;
}

.global-link-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
}

.mentions-container {
  display: flex;
  align-items: center;
}

.text-link {
  text-decoration: none;
  color: #fffeff;
  padding: 0 3px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.text-link:hover {
  color: #fde581;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}